import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faList } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/ED/icons/PNG/logo_2.png';
import './NavBars.css';

const NavBar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();

    const toggleMobileMenu = () => {
        setIsMobile(!isMobile);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const getLinkClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
            <NavLink to="/" >
          <img src={logo} alt="logo" />
            </NavLink>
            </div>
            <ul className={isMobile ? "navbar-links-mobile" : "navbar-links"} onClick={() => setIsMobile(false)} >
                <li className={getLinkClass('/')}>
                    <NavLink exact to="/" activeClassName="active">ACCUEIL</NavLink>
                </li>
                <li className={`dropdown ${getLinkClass('/services')}`} onClick={toggleDropdown}>
                    <NavLink to="/services/creation_site_web">SERVICE<i className="toggle-dropdown"><FontAwesomeIcon icon={faChevronDown} /></i></NavLink>
                    <ul className={isDropdownOpen ? "dropdown-menu show" : "dropdown-menu"} >
                        <li><NavLink to="/services/creation_site_web" activeClassName="active">Création de Site Web</NavLink></li>
                        <li><NavLink to="/services/refonte_site_web" activeClassName="active">Refonte Site Web</NavLink></li>
                        <li><NavLink to="/services/maintenance_securite" activeClassName="active">Maintenance et Sécurité</NavLink></li>
                        <li><NavLink to="/services/nom_domaine_hebergement" activeClassName="active">Nom de Domaine et Hébergement</NavLink></li>
                    </ul>
                </li>
                <li className={getLinkClass('/blog')}>
                    <NavLink to="/blog" activeClassName="active">BLOG</NavLink>
                </li>
            </ul>
            <div className={isMobile ? "navbar-contact-mobile" : "navbar-contact"}>
                <a className="btn-contact" href="#contact" >CONTACT</a>
            </div>
            <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                {isMobile ? <FaTimes /> : <FaBars />}
            </div>
        </nav>
    );
};

export default NavBar;
