import React from 'react'
import './HeroMaintenance.css'
import MaintenanceBg from '../../../assets/img/ED/service/maintenance_et_sécurité/bg_maintenance.png'
const HeroMaintenance = () => {
  return (
    <div className='hero_maintenance-container' style={{ backgroundImage: `url(${MaintenanceBg})` }}>
    <div className="hero_maintenance-content">
        <div className="hero_maintenance-text">
            <h1>Maintenance</h1>
            <h2>et sécurité</h2>
            <p>
            Il s'agit d'effectuer des tâches régulières pour garantir son bon fonctionnement<br/>sa sécurité et son efficacité continue.
            </p>
        </div>
        <div className="hero_maintenance-image">
            <div className="overlay"></div>
        </div>
    </div>

</div>
  )
}

export default HeroMaintenance
