import React from 'react'
import HeroMaintenance from './Maintenance/HeroMaintenance'
import ServiceMaintenance from './Maintenance/ServiceMaintenance'
import ServicesMaintenance from './Maintenance/Service__Maint'
const Maintenance = () => {
  return (
    <div>
        <HeroMaintenance />
       <ServicesMaintenance/>
    </div>
  )
}

export default Maintenance
