import React from 'react'
import './topbar.css'

import facebook from '../../assets/img/ED/icons/SVG/facebook_blue.svg'
import instagram from '../../assets/img/ED/icons/SVG/instagram_blue.svg'
import youtube from '../../assets/img/ED/icons/SVG/youtube.svg'
import linkedin from '../../assets/img/ED/icons/SVG/linkedin_blue.svg'
import email from '../../assets/img/ED/icons/SVG/email.svg'
import phone from '../../assets/img/ED/icons/SVG/phone.svg' 



const Topbar = () => {
  return (
<section id="topbar" className="d-flex align-items-center justify-content-between">
    <div className="container d-flex justify-content-between">
        <div className="contact-info d-flex align-items-center">
         
          <a href="mailto:service.client@devexcellence.net">  <img src={email} alt="Email" className="icon email-icon" />
           SERVICE.CLIENT@DEVEXCELLENCE.NET</a>
          
          <a href="tel:+21625628815"><img src={phone} alt="Phone" className="icon phone-icon" />
           +(216) 52 628 815</a>
        </div>

        <div className="social-links d-flex align-items-center">
          <a href="https://www.facebook.com/people/Dev-excellence/61553107216139" target="_blank" rel="noopener noreferrer" className="facebook">
            <img src={facebook} alt="Facebook" className="icon social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/dev-excellence/" target="_blank" rel="noopener noreferrer" className="linkedin">
            <img src={linkedin} alt="LinkedIn" className="icon social-icon" />
          </a>
          <a href="https://www.instagram.com/dev.excellence/?next=%2F" target="_blank" rel="noopener noreferrer" className="instagram">
            <img src={instagram} alt="Instagram" className="icon social-icon" />
          </a>
          <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer" className="youtube">
            <img src={youtube} alt="YouTube" className="icon social-icon" />
          </a>
        </div>
      </div>
    </section>
);
};


export default Topbar