import React from 'react'
import ServiceRefonte from './Refonte/ServiceRefonte'
import HeroRefonte from './Refonte/HeroRefonte'
const Refonte_site = () => {
  return (
    <div>
      <HeroRefonte />
      <ServiceRefonte />
    </div>
  )
}

export default Refonte_site
