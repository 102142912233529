import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import LogoFooter from '../../assets/img/ED/icons/SVG/Group 9.svg';
import Facebook from '../../assets/img/ED/icons/SVG/facebook.svg';
import Linkedin from '../../assets/img/ED/icons/SVG/linkedin.svg';
import Instagram from '../../assets/img/ED/icons/SVG/instagram.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section logo">
          <img src={LogoFooter} alt="Logo" />
        </div>
        <nav className="footer-section links">
          <Link to="/">ACCUEIL</Link>
          <Link to="/services/creation_site_web">SERVICES</Link>
          <Link to="/blog">BLOG</Link>
          <Link to="/home/contact">CONTACT</Link>
        </nav>
        <div className="footer-section social">
          <a href="https://www.facebook.com/people/Dev-excellence/61553107216139" target="_blank" rel="noopener noreferrer">
            <img src={Facebook} alt="Facebook" />
          
          </a>
          <a href="https://www.linkedin.com/company/dev-excellence/" target="_blank" rel="noopener noreferrer">
            <img src={Linkedin} alt="LinkedIn" />
          

          </a>
          <a href="https://www.instagram.com/dev.excellence/?next=%2F" target="_blank" rel="noopener noreferrer">
            <img src={Instagram} alt="Instagram" />
            

          </a>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; <strong><span>DEV EXCELLENCE 2024</span></strong>. Tous droits réservés.
      </div>
    </footer>
  );
};

export default Footer;
