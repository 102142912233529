import React from 'react'
import HeroDomain from './DomainName/HeroDomain'
import ServiceDomain from './DomainName/ServiceDomain'

const Nom_domaine = () => {
  return (
    <div>
      <HeroDomain />
      <ServiceDomain/>
    </div>
  )
}

export default Nom_domaine
