import React from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Navbar from './Components/Navbar/navbar';
import Home from './Pages/Home';
import Blog from './Pages/Blog/Blog';
import CreationSiteWeb from './Pages/Services/Creation_site';
import RefonteSiteWeb from './Pages/Services/Refonte_site';
import MaintenanceSecurite from './Pages/Services/Maintenance';
import NomDomaineHebergement from './Pages/Services/Nom_domaine';
import Topbar from './Components/Topbar/topbar';
import Footer from './Components/Footer/Footer'
import NavBar from './Components/Navbar/NavBars';
import './App.css'


function App() {
  return (
    <div className='app'>
               <BrowserRouter>
         <Topbar/>
    <NavBar/>
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/services/creation_site_web" element={<CreationSiteWeb />} />
      <Route path="/services/refonte_site_web" element={<RefonteSiteWeb />} />
      <Route path="/services/maintenance_securite" element={<MaintenanceSecurite />} />
      <Route path="/services/nom_domaine_hebergement" element={<NomDomaineHebergement />} />
      <Route path="/home/contact" element={<Home />} />
    </Routes>
    <Footer />
  </BrowserRouter>
    </div>

 
  );
}

export default App;
