import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './HeroRefonte.css'
import RefonteBg from '../../../assets/img/ED/service/Refonte_site_web/bg_refonte.png'
const HeroRefonte = () => {
  return (
    <div className='hero_refonte-container' style={{ backgroundImage: `url(${RefonteBg})` }}>
    <div className="hero_refonte-content">
        <div className="hero_refonte-text">
            <h1>refonte</h1>
            <h2>site web</h2>
            <p>
            implique la révision et la modification significative de l'apparence de la structure,
        et parfois même du contenu d'un site existant.
            </p>
        </div>
        <div className="hero_refonte-image">
            <div className="overlay"></div>
        </div>
    </div>

</div>
  )
}

export default HeroRefonte
