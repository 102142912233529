import React, { useState, useEffect } from 'react';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import './Blog.css'
import VideoBg from '../../assets/img/ED/hero.mp4'

const Blog = () => {
 

  return (
    <section className='blog'>
     
     <div className="video-container">
  <video src={VideoBg} autoPlay loop muted></video>
</div>


    <div className="blog__content">
      <h1>À venir</h1>
      <h3>
        Laissez votre email et nous vous le ferons savoir une fois la page ouverte</h3>

        <FlipClockCountdown
        to={new Date().getTime() + 24 * 3600 * 1000 + 5000}
        labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
        className='flip-clock '
        duration={0.5}
      >
        Finished
      </FlipClockCountdown>
      <button className='btn_blog'>Notification</button>
    </div>
    </section>
  );
};

export default Blog;
