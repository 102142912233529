import React, { Component } from 'react';
import './hero.css'
import videoBg from '../../assets/img/ED/hero.mp4'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaArrowRight } from 'react-icons/fa';

class Hero extends Component {
    componentDidMount() {
        AOS.init({ duration: 1000 });
    }

    render() {
        return (
            <section id="hero" className="hero d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="hero-p col-md-12">
                                <h1 data-aos="fade-up">dev excellence</h1>
                                <blockquote data-aos="fade-up" data-aos-delay="100">
                                    <p>
                                        Transformez vos idées en réalité avec notre <br />
                                        expertise en développement.
                                    </p>
                                </blockquote>
                                <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                                    <a href="#contact" className="btn btn-1">
                                        Contact <FaArrowRight />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 video-column">
                            <div className="video-background">
                                <video src={videoBg} autoPlay muted loop />
                            </div>
                        </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        );
    }
}

export default Hero;
