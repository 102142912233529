import React, { useRef } from 'react'
import './Contact.css'
import phone from '../../assets/img/ED/icons/PNG/phone.png'
import email from '../../assets/img/ED/icons/PNG/email.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_r1hm5lf', 'template_o5c5pu8', form.current, {
        publicKey: 'PHAU0cuqAcl7AAXul',
      })
      .then(
        () => {
          Swal.fire({
            title: "Success",
            text: "Message envoyer avec succès",
            icon: "success"
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };



  return (
    <section id="contact" className="contact">
    <div className="container position-relative" data-aos="fade-up">

      <div className="row gy-4 d-flex justify-content-end">

        <div className="col-lg-5" data-aos="fade-up" data-aos-delay="100">

          <div className="info-item d-flex">
           
            <div>
              <h1>CONTACT</h1>
              <p>Laisser-nous vous guider vers le poste qui <br/>vous convient le mieux en fonction de vos <br/>
                motivations contactez-nous dés aujourd'hui.
              </p>
            </div>
          </div>

          <div className="info-item d-flex">
            <i><FontAwesomeIcon icon={faEnvelope} /></i>
            <div>
              <h4>Email:</h4>
              <a href="mailto:service.client@devexcellence.net">Service.client@devexcellence.net</a>
            </div>
          </div>

          <div className="info-item d-flex">
            <i ><FontAwesomeIcon icon={faPhone} /> </i>
            <div>
              <h4>Numéro:</h4>
              <a href="tel:+21625628815">+(216) 52 628 815</a>
            </div>
          </div>

        </div>

        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">

          <form action="" method="post" ref={form} onSubmit={sendEmail} className="php-email-form">
            <div className="row">
              <div className="col-md-6 form-group">
                <input type="text" name="first_name" className="form-control" id="name" placeholder="NOM" required />
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input type="text" className="form-control" name="last_name" id="name" placeholder="PRENOM" required />
              </div>
            </div>
            <div className="form-group mt-3">
              <input type="email" className="form-control" name="email" id="email" placeholder="ADRESSE E-MAIL" required />
            </div>
            <div className="form-group mt-3">
              <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
            </div>
            <div className="text-center"><button type="submit" className="btn41-43 btn-41">Envoyer  <i><FontAwesomeIcon icon={faArrowRight} /></i></button></div>
          </form>

        </div>

      </div>

    </div>
  </section>

    
  )
}

export default Contact