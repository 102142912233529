import React from 'react'
import DomainBg from "../../../assets/img/ED/service/Nom_domaine_et_hébergement/bg_hebergement.png"
import './HeroDomain.css'
const HeroDomain = () => {
  return (
    <div className='hero_domain-container' style={{ backgroundImage: `url(${DomainBg})` }}>
    <div className="hero_domain-content">
        <div className="hero_domain-text">
            <h1>Nom de Domaine</h1>
            <br/>
            <h2>et hébergement</h2>
            <br/>
            <h4>NOM DE DOMAINE</h4>
            <p>
            Enregistrement et gestion du nom de domaine associé au site web Si vous ne possédez pas encore un nom de domaine pour votre site et aussi hébergement. Vous devez commencer par la réservation qui peut être sous le nom de votre entreprise ou votre produit en choisissant son extension.( Com, tn, net…)
            </p>
        </div>
        <div className="hero_domain-image">
            <div className="overlay"></div>
        </div>
    </div>

</div>
  
  )
}

export default HeroDomain
