import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink, Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import './Departments.css'

function Departments() {
  const [activeTab, setActiveTab] = useState('tab-1');

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="departments" className="departments">
      <div className="container" data-aos="fade-up">
        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <ul className="nav nav-tabs flex-column">
              <li className="nav-item">
                <a 
                  className={`nav-link ${activeTab === 'tab-1' ? 'active show' : ''}`} 
                  onClick={() => setActiveTab('tab-1')}
                >
                  <h1>Création de site web</h1>
                </a>
              </li>
              <li className="nav-item mt-2">
                <a 
                  className={`nav-link ${activeTab === 'tab-2' ? 'active show' : ''}`} 
                  onClick={() => setActiveTab('tab-2')}
                >
                  <h1>Refonte site web</h1>
                </a>
              </li>
              <li className="nav-item mt-2">
                <a 
                  className={`nav-link ${activeTab === 'tab-3' ? 'active show' : ''}`} 
                  onClick={() => setActiveTab('tab-3')}
                >
                  <h1>Maintenance et sécurité</h1>
                </a>
              </li>
              <li className="nav-item mt-2">
                <a 
                  className={`nav-link ${activeTab === 'tab-4' ? 'active show' : ''}`} 
                  onClick={() => setActiveTab('tab-4')}
                >
                  <h1>Nom de domaine et hébergement</h1>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-8">
            <div className="tab-content">
              <div className={`tab-pane ${activeTab === 'tab-1' ? 'active show' : ''}`} id="tab-1">
                <h3>Création de site web</h3>
                <p>Création d'un site web sur mesure, avec un design innovant, des fonctionnalités avancées et une expérience utilisateur exceptionnelle</p>
                <NavLink  to="/services/creation_site_web"><button type="button" className="btn41-43 btn-41">SERVICES INCLUTS <FaArrowRight /></button></NavLink>
              </div>
              <div className={`tab-pane ${activeTab === 'tab-2' ? 'active show' : ''}`} id="tab-2">
                <h3>Refonte site web</h3>
                <p>Implique la révision et la modification significative de l'apparence, de la structure, et parfois même du contenu d'un site existant.</p>
                <NavLink  to="/services/refonte_site_web"><button type="button" className="btn41-43 btn-41">SERVICES INCLUTS <FaArrowRight /></button></NavLink>
              </div>
              <div className={`tab-pane ${activeTab === 'tab-3' ? 'active show' : ''}`} id="tab-3">
                <h3>Maintenance et sécurité</h3>
                <p>Il s'agit d'effectuer des tâches régulières pour garantir son bon fonctionnement, sa sécurité et son efficacité continue.</p>
                <NavLink to="/services/maintenance_securite"><button type="button" className="btn41-43 btn-41">SERVICES INCLUTS <FaArrowRight /></button></NavLink>
              </div>
              <div className={`tab-pane ${activeTab === 'tab-4' ? 'active show' : ''}`} id="tab-4">
                <h3>Nom de domaine et hébergement</h3>
                <p>Si vous ne possédez pas encore de nom de domaine et un hébergement web pour votre site, commencez à réserver.</p>
                <NavLink to="/services/nom_domaine_hebergement"><button type="button" className="btn41-43 btn-41">SERVICES INCLUTS <FaArrowRight /></button></NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Departments;
