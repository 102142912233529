import React from 'react'
import './ServiceRefonte.css'
import Audit from "../../../assets/img/ED/service/Refonte_site_web/svg/audit.svg";
import Objectif from "../../../assets/img/ED/service/Refonte_site_web/svg/objectif.svg";
import Conception from "../../../assets/img/ED/service/Refonte_site_web/svg/conception.svg";
import DevIntegration from "../../../assets/img/ED/service/Refonte_site_web/svg/integration.svg";
import Migration from "../../../assets/img/ED/service/Refonte_site_web/svg/migration.svg";
import Test from "../../../assets/img/ED/service/Refonte_site_web/png/tests.png"
import Lancement from "../../../assets/img/ED/service/Refonte_site_web/svg/lancement.svg";
import Suivi from "../../../assets/img/ED/service/Refonte_site_web/svg/suivi.svg";
import Optimisation from "../../../assets/img/ED/service/Refonte_site_web/svg/optimisation.svg";
import bg from "../../../assets/img/ED/icons/BG/bg2.png";




const ServiceRefonte = () => {
  return (
    <div id="services_refonte" className="services_refonte">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <img src={Audit} alt="audit initial" />
              <h4>Audit initial</h4>

              <ul>
              <li>Évaluation de ce qui existe déjà : analyse du site web actuel, de son design, de ses fonctionnalités, de son contenu et de ses performances..</li>
                  <li>Évaluation des points forts et des points faibles..</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <img src={Objectif} alt="Objectifs de la refonte" />
              <h4>Objectifs de la refonte</h4>
              <ul>
              <li>Définition des objectifs spécifiques de la refonte, en fonction des besoins de l’entreprise <br/> et des attentes des utilisateurs.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <img src={Conception} alt="Conception et prototypage" />
              <h4>Conception et prototypage</h4>
              <ul>
              <li>Création de maquettes et de prototypes pour visualiser le nouveau design et l’expérience utilisateur prévue.</li>
              <li>Validation des concepts avec les parties prenantes.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <img src={DevIntegration} alt="développement et intégration" />
              <h4>développement et intégration</h4>
              <ul>
              <li>Mise en œuvre des nouvelles fonctionnalités, du design et de la structure technique.</li>
              <li>Intégration des nouvelles technologies ou frameworks si nécessaire.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="500">
              <img src={Migration} alt="Migration de contenu" />
              <h4>Migration de contenu</h4>
              <ul>
              <li>Transfert du contenu existant vers le nouveau site, en veillant à maintenir la cohérence et la pertinence.</li>

              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
              <img src={Test} alt="test" />
              <h4>Tests</h4>
              <ul>
                <li>
                Réalisation de tests approfondis pour s’assurer du bon fonctionnement du site dans différents navigateurs et appareils.
                </li>
                <li>Identification et résolution des bugs.</li>        
                </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="700">
              <img src={Lancement} alt="lancement" />
              <h4>Lancement</h4>
              <ul>
                <li>Déploiement du nouveau site.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="800">
              <img src={Suivi} alt="suivi aprés lancement" />
              <h4>Suivi aprés-lancement</h4>
              <ul>
                <li>Surveillance des performances du site après le lancement.</li>
                <li>Collecte des retours des utilisateurs et ajustements si nécessaire.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="icon-box"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              <img src={Optimisation} alt="optimisation" />
              <h4>optimisation pour les moteurs de recherche (SEO)</h4>
              <ul>
                <li>
                Révision et optimisation du contenu pour les moteurs de recherche.
                </li>
                <li>Mise en place de bonnes pratiques SEO pour améliorer la visibilité en ligne.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <img src={bg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRefonte
