import React from 'react'
import Hero from "../Components/Hero/hero";
import Departments from "../Components/Service/Departments";
import Contact from '../Components/Contact/Contact';

const Home = () => {
  return (
    <div> 
    <Hero />
    <br />
    <Departments />
    <br/>
    <Contact />
    <br />
    </div>
  )
}

export default Home