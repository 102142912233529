import React from 'react'
import './HeroCreations.css'
import 'bootstrap/dist/css/bootstrap.css';
import CreationBg from '../../../assets/img/ED/service/création_de_site_web/bg_creation.png'
const HeroCreations = () => {
  return (
    <div className='hero_creation-container' style={{ backgroundImage: `url(${CreationBg})` }}>
        <div className="hero_creation-content">
            <div className="hero_creation-text">
                <h1>création</h1>
                <h2>de site web</h2>
                <p>
                Création de site web personnalisé, alliant design 
            innovant, fonctionnalités avancées et expérience 
            utilisateur exceptionnelle
                </p>
            </div>
            <div className="hero_creation-image">
                <div className="overlay"></div>
            </div>
        </div>

    </div>
  )
}

export default HeroCreations
