import React from 'react';
import './ServiceDomain.css';
import 'bootstrap/dist/css/bootstrap.css';

import Assistance from '../../../assets/img/ED/service/Nom_domaine_et_hébergement/svg/assistance.svg';
import Configuration from '../../../assets/img/ED/service/Nom_domaine_et_hébergement/svg/configuration.svg';
import Hebergement from '../../../assets/img/ED/service/Nom_domaine_et_hébergement/svg/hebergement.svg';
import Performance from '../../../assets/img/ED/service/Nom_domaine_et_hébergement/svg/performance.svg';
import Sauvegarde from '../../../assets/img/ED/service/Nom_domaine_et_hébergement/svg/sauvegardes.svg';
import Stockage from '../../../assets/img/ED/service/Nom_domaine_et_hébergement/svg/stockage.svg';

const ServiceDomain = () => {
  return (
    <div id="services_domain" className="services_domain">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-between">
          <h1>Hébergement</h1>
          <p>
            Le choix d'un service d'hébergement adapté dépend des besoins spécifiques du site en termes de trafic, de contenu,
            de performances et de sécurité. Un hébergement de qualité contribue significativement à la réussite et à la stabilité d'un site web.
          </p>
        </div>
      </div>

      <div className="container">
        <div className="row features">
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <img src={Hebergement} alt="Hébergement sur serveurs" />
              <h4>Hébergement sur serveurs</h4>
              <ul>
                <li>Mise à disposition d'espaces de stockage sur des serveurs connectés en permanence à Internet.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <img src={Configuration} alt="Configuration du serveur" />
              <h4>Configuration du serveur</h4>
              <ul>
                <li>Configuration optimale du serveur en fonction des besoins du site, que ce soit un serveur partagé, un serveur dédié, un serveur virtuel (VPS), ou un service d'hébergement cloud.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <img src={Stockage} alt="Stockage" />
              <h4>Stockage</h4>
              <ul>
                <li>Attribution d'un espace de stockage en fonction des besoins du site pour héberger les fichiers, les images, les vidéos et autres contenus.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <img src={Sauvegarde} alt="Sauvegardes régulières" />
              <h4>Sauvegardes régulières</h4>
              <ul>
                <li>Mise en place de procédures de sauvegarde régulières pour prévenir la perte de données en cas de problème technique ou de sinistre.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="500">
              <img src={Assistance} alt="Assistance technique" />
              <h4>Assistance technique</h4>
              <ul>
                <li>Support technique pour résoudre les problèmes liés à l'hébergement, tels que les problèmes de connectivité, les erreurs de serveur, etc.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
              <img src={Performance} alt="Performances" />
              <h4>Performances</h4>
              <ul>
                <li>Optimisation des performances du serveur, y compris la gestion des ressources, la configuration du cache, et la minimisation des temps de chargement.</li>
              </ul>
            </div>
          </div>
        </div>
      
      </div>
      <div className="nb col-md-12">
          <p>NB: le nom de domaine et l'hébergement sous coût de prix unitaire du site</p>
        </div>
    </div>
  );
};

export default ServiceDomain;
