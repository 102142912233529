import React, { useState } from 'react';
import './navbar.css';
import logo from '../../assets/img/ED/icons/SVG/Group 10.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faList } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [click ,setClick] = useState (false)
  const  handleClick = () => setClick (!click)
  return (
    <div id="header" className="header d-flex align-items-center">
      <div className="container-fluid position-relative d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center me-auto me-xl-0">
          <img src={logo} alt="logo" />
        </Link>

        <nav className="navmenu" >
          <ul className={click ? "navmenu active" : "navmenu"}> 
            <li><Link exact to="/" activeClassName="active" onClick={handleClick}>Acceuil</Link></li>
            <li className="dropdown" onClick={handleClick} activeClassName="active">
              Services <i className="toggle-dropdown"><FontAwesomeIcon icon={faChevronDown} /></i>
              <ul>
                <li><Link to="/services/creation_site_web"activeClassName="active">Création de Site Web</Link></li>
                <li><Link to="/services/refonte_site_web" activeClassName="active">Refonte Site Web</Link></li>
                <li><Link to="/services/maintenance_securite" activeClassName="active">Maintenance et Sécurité</Link></li>
                <li><Link to="/services/nom_domaine_hebergement" activeClassName="active">Nom de Domaine et Hébergement</Link></li>
              </ul>
            </li>
            <li><Link to="/blog" onClick={handleClick} >Blog</Link></li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none0" onClick={toggleMenu}><FontAwesomeIcon icon={faList} /></i>
        </nav>

        <Link className="btn-contact" to="/home/contact">Contact</Link>
      </div>
    </div>
  );
};

export default Navbar;
