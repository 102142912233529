import React from "react";
import "./Service__Maint.css";
import "bootstrap/dist/css/bootstrap.css";
import Certificat from "../../../assets/img/ED/service/maintenance_et_sécurité/svg/certificat.svg";
import Optimisation from "../../../assets/img/ED/service/maintenance_et_sécurité/svg/optimisation.svg";
import PareFeu from "../../../assets/img/ED/service/maintenance_et_sécurité/svg/parefeu.svg";
import Sauvegarde from "../../../assets/img/ED/service/maintenance_et_sécurité/svg/Sauvegarde.svg";
import Sécurité from "../../../assets/img/ED/service/maintenance_et_sécurité/svg/sécurité.svg";
import Surveillance from "../../../assets/img/ED/service/maintenance_et_sécurité/svg/surveillance.svg";
import Bg from "../../../assets/img/ED/icons/BG/bg2.png";

const ServicesMaintenance = () => {
  return (
    <div id="services_maintenance" className="services_maintenance">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
       

      <div className="row justify-content-between">
        <h1>Maintenance</h1>
        </div>
      </div>
        <div className="row features">
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
            <img src={Sauvegarde}  alt="Sauvegardes régulières" />
            <h4>Sauvegardes régulières</h4>
              <ul>
              <li>Mise en place de sauvegardes fréquentes des données du site pour prévenir la perte d'informations en cas de problème technique ou de cyberattaque.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature ">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
            <img src={Surveillance} alt="Surveillance continue"  />
            <h4>Surveillance continue</h4>
              <ul>
              <li>Utilisation d'outils de surveillance pour détecter les anomalies, les erreurs et les temps d'arrêt du site.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature ">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
            <img src={Optimisation} alt="Optimisation des performances"  />
              <h4>Optimisation des performances</h4>
              <ul>
              <li>Identification et résolution des problèmes de performance pour garantir une expérience utilisateur optimale.</li>
              </ul>
            </div>
          </div>
          <div className="row justify-content-between">
        <h1>Sécurité</h1>
        </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
            <img src={PareFeu} alt="Mise en place de pare-feu"  />
            <h4>Mise en place de pare-feu</h4>
              <ul>
              <li>Configuration de pare-feu pour protéger le site contre les attaques de type injection SQL, cross-site scripting (XSS) et autres vulnérabilités.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="500">
            <img src={Certificat} alt="Certificat SSL" />
            <h4>Certificat SSL</h4>
              <ul>
              <li>Installation et maintenance d'un certificat SSL pour assurer le chiffrement des données entre le site et les utilisateurs.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 feature">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
            <img src={Surveillance} alt="Surveillance de la sécurité"  />
            <h4>Surveillance de la sécurité</h4>
              <ul>
              <li>Mise en place de systèmes de détection des intrusions et de surveillance en temps réel pour réagir rapidement aux menaces potentielles. Intervention rapide en cas de problème ou de menace de sécurité.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
  );
};

export default ServicesMaintenance;
