import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "./ServiceCreation.css";
import Consultaion from "../../../assets/img/ED/service/création_de_site_web/svg/consultation.svg";
import Conception from "../../../assets/img/ED/service/création_de_site_web/svg/conception.svg";
import FrontEnd from "../../../assets/img/ED/service/création_de_site_web/svg/dev_front.svg";
import BackEnd from "../../../assets/img/ED/service/création_de_site_web/svg/dev_back.svg";
import Integration from "../../../assets/img/ED/service/création_de_site_web/svg/integration.svg";
import Test from "../../../assets/img/ED/service/création_de_site_web/svg/Test.svg";
import Lancement from "../../../assets/img/ED/service/création_de_site_web/svg/lancement.svg";
import Suivi from "../../../assets/img/ED/service/création_de_site_web/svg/suivi.svg";
import Optimisation from "../../../assets/img/ED/service/création_de_site_web/svg/optimisation.svg";
import bg from "../../../assets/img/ED/icons/BG/bg2.png";

const ServiceCreation = () => {
  return (
    <div id="services_creation" className="services_creation">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <img src={Consultaion} alt="consultation" />
              <h4>consultation initiale et planification</h4>

              <ul>
                <li>
                  Discussion avec le client pour comprendre ses
                  besoins,objectifs et préférences.
                </li>
                <li>
                  Présentation de la structure du site, de l'architecture des
                  données et des fonctionnalités
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <img src={Conception} alt="conception" />
              <h4>Conception</h4>
              <ul>
                <li>
                  Conception de maquettes et de prototypes pour observer le design et l'expérience utilisateur prévue.
                </li>
                <li>
                  Choix de la palette de couleurs, des polices et des éléments graphiques.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <img src={FrontEnd} alt="dev front end" />
              <h4>développement front-end</h4>
              <ul>
                <li>
                  Mise en place de la partie visible du site afin que les utilisateurs puissent y voir et interagir.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <img src={BackEnd} alt="dev back end" />
              <h4>développement back-end</h4>
              <ul>
                <li>
                  Mise en place des fonctionnalités et des bases de données nécessaires.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="500">
              <img src={Integration} alt="intégration" />
              <h4>intégration</h4>
              <ul>
                <li>
                  Assurer une liaison parfaite entre le front-end et le back-end.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
              <img src={Test} alt="test" />
              <h4>Tests</h4>
              <ul>
                <li>
                  Effectuer des tests approfondis pour garantir le bon fonctionnement du site sur différents navigateurs et appareils.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="700">
              <img src={Lancement} alt="lancement" />
              <h4>Lancement</h4>
              <ul>
                <li>Installation du site sur un serveur.</li>
                <li>Configuration du nom de domaine et publication du site.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="800">
              <img src={Suivi} alt="suivi aprés lancement" />
              <h4>Suivi après-lancement</h4>
              <ul>
                <li>Contrôle des performances du site après le démarrage.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="900">
              <img src={Optimisation} alt="optimisation" />
              <h4>optimisation pour les moteurs de recherche (SEO)</h4>
              <ul>
                <li>
                  Révision et optimisation du contenu pour les moteurs de recherche.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <img src={bg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCreation;
