import React from 'react'
import HeroCreations from './Creation/HeroCreations'
import ServiceCreation from './Creation/ServiceCreation'

const Creation_site = () => {
  return (
    <div className='creation_site'>
      <HeroCreations />
      <br />
      <ServiceCreation />
    </div>
  )
}

export default Creation_site
